export namespace Tariff {
	export type ShortTariffInfo = Pick<TariffWithSettings, 'id' | 'name' | 'applyOnRequest'>;

	export type TariffsInfoResponse = {
		tariffs: TariffWithSettings[];
		tariffsInfo: TariffInfo[];
	};

	export enum TariffName {
		Basic = 'Basic',
		Silver = 'Silver',
		Gold = 'Gold',
		PlatinumDiamond = 'Platinum / Diamond',
	}

	export type TariffWithSettings = {
		id: number;
		description: string;
		bestChoice: boolean;
		name: TariffName;
		priceDescription: string;
		prompt: string;
		order: number;
		applyOnRequest: boolean;
		settings: Setting[];
		defaultDurationInMonths: number;
	};

	export type Setting = {
		infoId: TariffInfo['id'];
		value: string | boolean;
		description: string;
	};

	export type TariffInfo = {
		id: string;
		name: string;
		prompt: string;
		order: number;
		description: string;
	};
}
