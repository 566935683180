export const en = {
	'Лучший выбор!': 'Best price!',
	'с 1 Апреля 2024 года': 'from April 1, 2024',
	Купить: 'Purchase',
	Сменить: 'Change',
	Запросить: 'Request',
	Тарифы: 'Tariffs',
	выгодно: 'best price',
	Выбрать: 'Select',
	'Без комиссии': 'Without commission',
	'Ваш текущий тариф': 'Current tariff',
	'Показать все преимущества': 'Show all benefits',
	'Скрыть преимущества': 'Hide benefits',
	'С подробными условиями тарификации можно ознакомиться, связавшись с нами по телефону +7 908 999 80 80.':
		'You can get acquainted with the detailed terms of the tariff by contacting us by phone +7 908 999 80 80.',

	'Произошла ошибка при отправке заявки': 'An error occurred while sending the application',
	'Пожалуйста, повторите запрос': 'Please try again',
	'Ваша заявка успешно отправлена': 'Your application has been successfully sent',
};
