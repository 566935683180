import React, { FC, useContext, useMemo, useState } from 'react';

import Image from 'next/image';

import { Button, Tooltip } from 'antd';
import cn from 'classnames';

import { createApplicationPayment } from '@/api/create-application-payment';
import IncludedIcon from '@/assets/included-icon.svg';
import PromptIcon from '@/assets/prompt-icon.svg';
import UnincludedIcon from '@/assets/unincluded-icon.svg';
import { ApplicationPaymentType } from '@/constants/application-payments';
import { FeedbackContext } from '@/context/feeadback.context';
import { useTranslate } from '@/hooks/use-translate';
import { Tariff } from '@/types/tariff';

import { en } from '../en';
import { InvoiceModal } from '../InvoiceModal/InvoiceModal';
import { InvoiceFieldType } from '../InvoiceModalContent/InvoiceModalContent';
import { TariffsTableProps } from '../TariffsTable';
import { getTariffsInitialState } from '../utils';

import styles from './TariffsTableMobile.module.scss';

type OwnProps = TariffsTableProps & { tariffsInfoData: Tariff.TariffsInfoResponse };

export const TariffsTableMobile: FC<OwnProps> = ({
	tariffsInfoData,
	onTariffChoice,
	showButtons,
	tariffChoiceDisable,
	currentTariff,
	isActiveTariff,
	userIsAuth,
}) => {
	const [isShowMore, setIsShowMore] = useState<boolean>(true);
	const [slicedItems, setSlicedItems] = useState(getTariffsInitialState(tariffsInfoData));
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const { notify } = useContext(FeedbackContext);

	const t = useTranslate(en);

	const showModal = () => {
		setIsOpenModal(true);
	};

	const hideModal = () => {
		setIsOpenModal(false);
	};

	const handleClickVipTariff = () => {
		showModal();
	};

	const handleSendInvoice = async (formData: InvoiceFieldType) => {
		const { name, phone, email, comment } = formData;

		try {
			setIsLoading(true);

			await createApplicationPayment({
				type: ApplicationPaymentType.Tariff,
				infoForRequest: {
					name,
					phone,
					email,
					comment,
				},
				tariff: vipTariff.id,
			});

			notify.success({
				message: t('Ваша заявка успешно отправлена'),
			});
			hideModal();
		} catch (error) {
			notify.error({
				message: error?.message ?? t('Произошла ошибка при отправке заявки'),
				description: t('Пожалуйста, повторите запрос'),
			});
		} finally {
			setIsLoading(false);
		}
	};

	const vipTariff = useMemo(() => {
		return tariffsInfoData.tariffs.find((tariff) => tariff.applyOnRequest);
	}, [tariffsInfoData]);

	const renderButton = (tariff: Tariff.TariffWithSettings) => {
		const tariffButtonText =
			tariff.name === Tariff.TariffName.PlatinumDiamond ? 'Запросить' : 'Выбрать';

		if (!showButtons) return null;

		if (!userIsAuth) return getButton(tariff, t(tariffButtonText));

		if ((currentTariff && !isActiveTariff) || !currentTariff) {
			return getButton(tariff, t(tariffButtonText));
		}

		if (currentTariff === tariff.id)
			// true for old perYear flag
			return <p className={styles.currentTariff}>{t('Ваш текущий тариф')}</p>;

		return getButton(tariff, t('Сменить'));
	};

	const getButton = (tariff: Tariff.TariffWithSettings, text: string) => {
		if (tariff.name === Tariff.TariffName.Silver) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					onClick={() => onTariffChoice({ tariffInfo: tariff, per_three_month: true })}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
		if (tariff.name === Tariff.TariffName.Gold) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					onClick={() => onTariffChoice({ tariffInfo: tariff, perYear: true })}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
		if (tariff.name === Tariff.TariffName.PlatinumDiamond) {
			return (
				<Button
					className={styles.choiceButton}
					type="primary"
					onClick={handleClickVipTariff}
					disabled={tariffChoiceDisable}
				>
					{text}
				</Button>
			);
		}
	};

	const renderSettingCell = (setting: Tariff.Setting) => {
		if (typeof setting.value === 'string') {
			return setting.value === 'Без комиссии' ? (
				<div className={styles.withoutCommission}>{setting.value}</div>
			) : (
				<div>{setting.value}</div>
			);
		}

		return setting.value ? (
			<Image
				className={styles.icon}
				width={24}
				height={24}
				alt="included"
				src={IncludedIcon}
			/>
		) : (
			<Image
				className={styles.icon}
				width={24}
				height={24}
				alt="unincluded"
				src={UnincludedIcon}
			/>
		);
	};

	const handleClickShowMore = () => {
		if (isShowMore) {
			setSlicedItems(tariffsInfoData.tariffsInfo);
		} else {
			setSlicedItems(getTariffsInitialState(tariffsInfoData));
		}

		setIsShowMore(!isShowMore);
	};

	return (
		<div className={styles.container}>
			<h2 className={styles.tariffHeader}>{t('Тарифы')}</h2>
			<div className={styles.tariffDate}>{t('с 1 Апреля 2024 года')}</div>

			<div className={styles.tariff}>
				{tariffsInfoData.tariffs.map((tariff) => {
					return (
						<div key={tariff.id}>
							{tariff.bestChoice && (
								<div className={styles.bestChoiceLabel}>Лучший выбор!</div>
							)}
							<div className={styles.tariffSection}>
								<div className={styles.tariffInfo}>
									<div className={styles.tariffName}>{tariff.name}</div>
									<div className={styles.tariffPrompt}>{tariff.prompt}</div>
									<div className={styles.tariffParameters}>
										{slicedItems.map((tariffSetting, index) => {
											const tariffSettingInfo = tariff.settings.find(
												(item) => item.infoId === tariffSetting.id,
											);
											const isFirstItem = index === 0;

											return (
												<div
													className={styles.tariffSettingPoint}
													key={tariffSettingInfo.infoId}
												>
													<div className={styles.tariffSettingTitle}>
														<div className={styles.tariffSettingLabel}>
															{tariffSetting.name}
														</div>
														{tariffSetting.prompt && (
															<Tooltip
																color="white"
																overlayClassName={
																	styles.tooltipContainer
																}
																overlayInnerStyle={{
																	color: '#0a2653',
																	padding: '1.4rem',
																}}
																title={tariffSetting.prompt}
															>
																<Image
																	className={styles.hint}
																	src={PromptIcon}
																	alt="prompt-icon"
																/>
															</Tooltip>
														)}
													</div>
													<div
														className={cn(styles.tariffSettingValue, {
															[styles.boldValue]: isFirstItem,
														})}
													>
														{renderSettingCell(tariffSettingInfo)}
													</div>
													{tariffSetting.description && (
														<div
															className={
																styles.tariffSettingDescription
															}
														>
															{tariffSetting.description}
														</div>
													)}
												</div>
											);
										})}
									</div>
									<Button
										className={styles.showMoreButton}
										onClick={handleClickShowMore}
									>
										{t(
											isShowMore
												? 'Показать все преимущества'
												: 'Скрыть преимущества',
										)}
									</Button>
									{renderButton(tariff)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			{isOpenModal && (
				<InvoiceModal
					isOpenModal={isOpenModal}
					description="Менеджер Fishplace свяжется с вами — расскажет об условиях тарифа, преимуществах платформы и поможет с регистрацией."
					isLoading={isLoading}
					title="Запрос VIP-тарифа"
					onHideModal={hideModal}
					onSendInvoice={handleSendInvoice}
				/>
			)}
		</div>
	);
};
