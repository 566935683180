import React, { FC } from 'react';

import { Button, Form, Input } from 'antd';

import { useTranslate } from '@/hooks/use-translate';

import { en } from './en';

import styles from './InvoiceModalContent.module.scss';

type Props = {
	isLoading: boolean;
	description: string;
	onSendInvoice: (formData: InvoiceFieldType) => void;
};

export type InvoiceFieldType = {
	name: string;
	phone: string;
	email: string;
	comment?: string;
};

const initialValues: InvoiceFieldType = {
	name: '',
	phone: '',
	email: '',
	comment: '',
};

export const InvoiceModalContent: FC<Props> = ({ isLoading, description, onSendInvoice }) => {
	const t = useTranslate(en);

	const { TextArea } = Input;

	return (
		<div className={styles.contentContainer}>
			<div className={styles.description}>{t(description)}</div>
			<Form initialValues={initialValues} onFinish={onSendInvoice}>
				<Form.Item<InvoiceFieldType>
					name="name"
					rules={[
						{
							required: true,
							message: (
								<span style={{ padding: '4px 0', fontSize: '1.3rem' }}>
									{t('Пожалуйста введите ваше имя')}
								</span>
							),
						},
					]}
				>
					<Input className={styles.input} placeholder={t('Ваше имя')} />
				</Form.Item>
				<Form.Item<InvoiceFieldType> name="phone">
					<Input className={styles.input} placeholder={t('Телефон')} />
				</Form.Item>
				<Form.Item<InvoiceFieldType>
					name="email"
					rules={[
						{
							required: true,
							type: 'email',
							message: (
								<span style={{ padding: '4px 0', fontSize: '1.3rem' }}>
									{t('Пожалуйста введите адрес электронной почты')}
								</span>
							),
						},
					]}
				>
					<Input className={styles.input} placeholder={t('Эл. почта')} />
				</Form.Item>
				<Form.Item<InvoiceFieldType> name="comment">
					<TextArea
						className={styles.input}
						autoSize={{ minRows: 4, maxRows: 8 }}
						placeholder={t('Дополнительная информация')}
					/>
				</Form.Item>
				<Button
					loading={isLoading}
					className={styles.button}
					size="large"
					type="primary"
					block
					htmlType="submit"
				>
					{t('Отправить')}
				</Button>
			</Form>
		</div>
	);
};
