import axios from 'axios';
import cookies from 'js-cookie';

import { TOKEN_COOKIE_NAME } from '@/auth/constants';
import { ApplicationPayment, ApplicationPaymentRequest } from '@/types/application-payment';

export async function createApplicationPayment(applicationPayment: ApplicationPaymentRequest) {
	const token = cookies.get(TOKEN_COOKIE_NAME);
	const headers = token ? { Authorization: `Bearer ${token}` } : {};

	const { data } = await axios.post<ApplicationPayment>(
		`${process.env.HOST}/api_v1/applications_payment/create/`,
		applicationPayment,
		{ headers },
	);

	return data;
}
