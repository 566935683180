export const en = {
	// titles
	'Демонстрация платформы': 'Platform demonstration',
	'Запрос VIP-тарифа': 'Request VIP tariff',

	// description
	'Менеджер Fishplace свяжется с вами — расскажет об условиях тарифа, преимуществах платформы и поможет с регистрацией.':
		'Fishplace manager will contact you — tell you about the terms of the tariff, the advantages of the platform and help with registration',
	'Менеджер Fishplace свяжется с вами — расскажет о преимуществах, покажет возможности, расскажет о том, как выгодно использовать платформу.':
		'Fishplace manager will contact you — tell you about the platform, conduct a demonstration, help you get started',

	// fields
	'Ваше имя': 'Your name',
	Телефон: 'Phone',
	'Эл. почта': 'Email',
	'Дополнительная информация': 'Additional information',

	// button text
	Отправить: 'Send',

	// notifications messages
	'Произошла ошибка при отправке заявки': 'An error occurred while sending the application',
	'Пожалуйста, повторите запрос': 'Please try again',
	'Ваша заявка успешно отправлена': 'Your application has been successfully sent',

	// validation messages
	'Пожалуйста введите ваше имя': 'Please enter your name',
	'Пожалуйста введите адрес электронной почты': 'Please enter your email address',
};
