import React, { FC } from 'react';

import { Modal } from 'antd';

import { pxToRem } from '@/utils/common';
import { CloseOutlined } from '@ant-design/icons';

import { InvoiceFieldType, InvoiceModalContent } from '../InvoiceModalContent/InvoiceModalContent';

import styles from './InvoiceModal.module.scss';

type Props = {
	isOpenModal: boolean;
	title: string;
	description: string;
	isLoading: boolean;
	onSendInvoice: (formData: InvoiceFieldType) => void;
	onHideModal: () => void;
};
export const InvoiceModal: FC<Props> = ({
	isOpenModal,
	isLoading,
	title,
	description,
	onSendInvoice,
	onHideModal,
}) => {
	return (
		<Modal
			open={isOpenModal}
			title={<div className={styles.modalTitle}>{title}</div>}
			className={styles.antContent}
			closeIcon={<CloseOutlined className={styles.closeIcon} />}
			footer={null}
			maskClosable={true}
			closable={true}
			keyboard={true}
			width={pxToRem(552 + 96)}
			onCancel={onHideModal}
		>
			<InvoiceModalContent
				description={description}
				isLoading={isLoading}
				onSendInvoice={onSendInvoice}
			/>
		</Modal>
	);
};
