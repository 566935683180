export enum ApplicationPaymentType {
	Payment,
	Tariff,
	Demo,
}

export enum ApplicationPaymentStatus {
	New = 0,
	ExpectPayment = 1,
	Paid = 2,
	Completed = 3,
	Rejected = 4,
}
