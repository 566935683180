import axios from 'axios';

import { Tariff } from '@/types/tariff';

/**
 * Получает список тарифов
 */
export async function getTariffs(params: { lang?: string } = {}) {
	const { data } = await axios.get<Tariff.TariffsInfoResponse>(
		`${process.env.HOST}/api_v1/tariff/`,
		{
			params,
		},
	);

	return data;
}
